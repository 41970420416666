import BaseComponent from "lib@/base_component";
import User from "lib@/user";

/**
 * 页面布局的组件
 */
class LayoutCpn extends BaseComponent
{
    constructor(cfg = null){
        super();

        const defaultCfg = {
            auto_init: true,
        }

        this._cfg = {
            ...defaultCfg,
            ...cfg,
        };

        if(this._cfg.auto_init){
            this.init();
        }
    }

    init(){
        this._cfg.App.getTpl('layout/default').then((tpl) => {
            this._cfg.App.res.render(tpl({
                userData: User.getUserData(),
            }));

            layui.use(['element'], function(){
                var element = layui.element;
                element.render();
            });

            $('#fold_bar').on('click', function (event){
                let mySelf    = $(this);
                let foldKey   = 'data-folded';
                let foldVal   = '';
                let iElm      = mySelf.children('i').first();
                let sideElm   = $('.layui-layout-admin>.layui-side');
                let topBarElm = $('#main_top_bar');
                let bodyElm   = $('#main_body');

                iElm.removeClass('my-anim-rotate-half my-anim-rotate-half-reverse');
                // 菜单展开的操作
                if(mySelf.attr(foldKey) == '1'){
                    iElm.addClass('my-anim-rotate-half-reverse');
                    sideElm.show();
                    mySelf.css('left', '200px');
                    topBarElm.css('padding-left', '200px');
                    bodyElm.css('left', '200px');
                }
                // 菜单收起的操作
                else{
                    foldVal = '1';
                    iElm.addClass('my-anim-rotate-half');
                    sideElm.hide();
                    mySelf.css('left', '0px');
                    topBarElm.css('padding-left', '0px');
                    bodyElm.css('left', '0px');
                }

                mySelf.attr(foldKey, foldVal);
                $(window).trigger('resize');
                layui.$(window).trigger('resize');
            });
        });
    }
}

export default LayoutCpn;
import SMERouter from 'sme-router';
import artRuntime from 'art-template/lib/runtime';
import User from 'lib@/user';
import tagList from 'lib@/tag_extends/tag';
import { urlEncode, log, getObjProperty, myRound } from 'lib@/util';


/**
 * 平台入口的公共部分
 */

const router = new SMERouter('main_page', 'html5');

App.router = router;

// 为 art-template 设置相关属性
artRuntime.log            = log;
artRuntime.getObjProperty = getObjProperty;
artRuntime.ceil           = Math.ceil;
artRuntime.myRound        = myRound;

// 为 art-template 设置tag对应的方法
for(let k in tagList){
    artRuntime[k] = tagList[k];
}

// 超链接支持路由
$('body').on('click', 'a[route-url]', function (event){
    let elm = $(this);
    event.preventDefault();
    App.router.go(elm.attr('href'));
});

$('body').on('click', 'a[route-goback]', function (event){
    event.preventDefault();
    App.router.back();
});

// 设置了 lay-filter="get-query" 的提交操作，改为 router 的 url 跳转
layui.form.on('submit(get-query)', (data) => {
    let action = $(data.form).attr('action') || (App.req.url.split('?'))[0];

    App.router.go(`${action}?${urlEncode(data.field)}`);

    return false;
});

/**
 * 最后一个显示的页面对象
 */
let lastPage = null;

export function showPage(page){
    if(lastPage){
        lastPage.leave();
    }

    if(!page){
        return;
    }

    page.enter();

    lastPage = page;
}

/**
 * 因为默认路由要在所有的路由之后，所以单独写一个函数，需要在入口文件中调用
 * @param {object} req 请求对象
 * @param {object} res 响应对象
 * @param {function} next 
 * @returns void
 */
export function defaultRoute(req, res, next) {
    let url = (req.url.split('?'))[0];
    let defaultRoute = User.getDefaultRoute();
    if(/^(\/|\/index)(\?.*){0,1}$/.test(url)){
        console.log(defaultRoute)
        if(defaultRoute != ''){
            router.redirect(defaultRoute);
        }
        else{
            router.go('/login');

        }
        return;
    }
    // 没有默认路由表示未登录
    else if(!defaultRoute){
        router.go('/login');
        return;
    }
    App.req  = req;
    App.res  = res;
    App.next = next;
    App.outputLayout().then(() => {
        return App.getPage(url);
    })
    .then((page) => {
        showPage(page);
    });
}

router.route(
    '/404',
    (req, res, next) => {

        App.req  = req;
        App.res  = res;
        App.next = next;

        App.getPage('error404').then((page) => {
            showPage(page);
        });
    }
);
import { showPage, defaultRoute } from './base';
import app from 'lib@/app';
import LayoutCpn from 'cpn@/layout_cpn';

/**
 * 默认入口 advisor.huxihome.com
 */

app.entryPoint = 'index';
app.setDefaultLayoutCpn(new LayoutCpn({
    App      : app,
    auto_init: false,
}));

let router = app.router;

router.route(
    '/login',
    (req, res, next) => {
        App.req  = req;
        App.res  = res;
        App.next = next;

        App.getPage('login').then((page) => {
            showPage(page);
        });
    }
);

router.route(
    '*',
    (req, res, next) => {
        defaultRoute(req, res, next);
    }
);